'use client';

import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import { Box, Flex, Heading, Text, useTokens } from '@mezzoforte/forge';
import { CheckCircle } from '@mezzoforte/forge-icons';
import { useHermesEntry } from '@/hooks/useHermesEntry';
import { HuutokaupatImage } from '@/components/Image/HuutokaupatImage';
import { CompanyName } from '@/components/CompanyName/CompanyName';
import { EntryCardVariant, variantStyles } from '@/util/entryCardVariants';
import { ToggleFavoriteButton } from '@/components/Favorites/ToggleFavoriteButton';
import { CustomBadge, CustomBadges } from '@/components/CustomBadge/CustomBadge';
import type { ListEntry } from '@/types/ListEntry';
import { formatCurrency } from '@/util/currency';
import { EntryListItem } from '@/components/EntryList/EntryListItem';
import { TimeLeft, TimeLeftDescription } from '@/components/Entry/TimeLeft';
import { linkToEntryPage } from '@/util/entry';
import { listEntryToEcommerceItem } from '@/util/ecommerce';
import { useCategories } from '@/hooks/useCategories';
import { gtmService } from '@/util/gtm-service';
import { ListSmsNotificationToggle } from '@/components/Favorites/ListSmsNotificationToggle';
import { useSession } from '@/hooks/useSession';
import { EntryCardBidStatus } from '@/components/EntryList/EntryCardBidStatus';
import { useVatlessSelection } from '@/hooks/useVatlessSelection';
import { priceWithoutVat } from '@/util/price';
import HuutoHulinatIcon from 'public/images/huutohulinat_bottomleft.svg';

const { breakpoints, fontSizes } = useTokens.huutokaupat;

const EntryCardLink = styled(Link)`
  :focus {
    box-shadow: none;
  }

  :hover {
    text-decoration: none;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
`;

const Subtitle = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;

  @media screen and (max-width: ${breakpoints.md}) {
    -webkit-line-clamp: 3;
  }
`;

interface EntryCardProps {
  readonly entry: ListEntry;
  readonly variant?: EntryCardVariant;
  readonly analytics: {
    readonly listId: Lowercase<string>;
    readonly listName: string;
    readonly sourceEntryId?: number;
    readonly index: number;
  };
  readonly onClick?: () => void;
  readonly isFavoriteEntry?: boolean;
}

function BidCount({ entry }: { readonly entry: ListEntry }) {
  if (entry.bidCount === 0) return <>lähtöhinta</>;
  return `${entry.bidCount} tarjous${entry.bidCount !== 1 ? 'ta' : ''}`;
}

function EntryCard({
  entry: rawEntry,
  variant = 'default',
  isFavoriteEntry = false,
  analytics,
  onClick: onClickProp,
}: EntryCardProps) {
  const { hermesEntry } = useHermesEntry(rawEntry.id, rawEntry.vatPerc);
  const { data: categoriesData } = useCategories();
  const { currentUser } = useSession();
  const { showVatlessPrice } = useVatlessSelection();

  const { colors } = useTokens.huutokaupat;

  const entry: ListEntry = {
    ...rawEntry,
    bidCount: hermesEntry?.bidCount ?? rawEntry.bidCount,
    auctionEnd: hermesEntry?.auctionEnd ?? rawEntry.auctionEnd,
    ended: hermesEntry?.isEnded ?? rawEntry.ended,
    continuousAuction: hermesEntry?.isContinuous ?? rawEntry.continuousAuction,
    highestBid: hermesEntry && hermesEntry.bids?.length > 0 ? hermesEntry.bids[0].amount : rawEntry.highestBid,
  };

  const categories = categoriesData?.categories ?? [];

  const badges = [
    {
      key: 'custom',
      enabled: entry.customBadge,
      label: entry.customBadge && <CustomBadge badgeKey={entry.customBadge} />,
      background: entry.customBadge ? CustomBadges[entry.customBadge].background : undefined,
    },
    {
      key: 'sold-to-highest-bidder',
      enabled: entry.soldToHighestBidder,
      label: 'Myydään eniten tarjoavalle',
      background: 'backgroundSuccess',
    },
    {
      key: 'verified',
      enabled: entry.isVerifiedEntry,
      label: (
        <>
          <CheckCircle
            weight="fill"
            color={colors.success}
            size={16}
          />{' '}
          Tarkastettu kohde
        </>
      ),
      background: 'backgroundSuccess',
    },
  ];

  const entryLink = linkToEntryPage(entry);

  const displayPrice = useMemo(() => {
    if (showVatlessPrice && entry.vatPerc > 0) {
      return priceWithoutVat(entry.highestBid ?? entry.startPrice);
    }
    return entry.highestBid ?? entry.startPrice;
  }, [showVatlessPrice, entry.vatPerc, entry.highestBid, entry.startPrice]);

  const onClick = () => {
    onClickProp?.();
    gtmService.recommendedEcommerce.selectItem(
      analytics.listId,
      analytics.listName,
      listEntryToEcommerceItem(entry, categories, analytics.index),
      analytics.sourceEntryId ? { source_entry_id: analytics.sourceEntryId } : {}
    );
  };

  return (
    <EntryListItem
      data-test={`entry-card-${entry.id}`}
      position="relative"
      style={{
        filter: entry.ended ? 'grayscale(100%)' : 'none',
        opacity: entry.ended ? 0.7 : 1,
        overflowAnchor: 'none',
      }}
      {...variantStyles.container[variant]}
    >
      <Flex
        direction="column"
        grow={1}
        {...variantStyles.itemInfo[variant]}
      >
        <EntryCardLink
          onClick={onClick}
          href={entryLink}
          data-test={`entry-card-link-${entry.id}`}
        >
          <Heading
            variant="h5"
            as="h3"
            marginBottom={2}
            display="-webkit-box"
            overflow="hidden"
            {...variantStyles.title[variant]}
          >
            {entry.title}, {entry.city}
          </Heading>
        </EntryCardLink>
        {entry.subtitle && (
          <Subtitle
            variant="requiredbylaw"
            color="text"
            mb={2}
            hidden={isFavoriteEntry}
          >
            {entry.subtitle}
          </Subtitle>
        )}
        {entry.companyName && (
          <Subtitle
            variant="requiredbylaw"
            mb={2}
            hidden={isFavoriteEntry}
          >
            <CompanyName
              isOwnPayment={entry.isOwnPayment}
              companyId={entry.companyId}
              companyName={entry.companyName}
            />
          </Subtitle>
        )}

        {isFavoriteEntry && (
          <EntryCardBidStatus
            entryId={entry.id}
            isCancelled={entry.cancelled}
            bids={hermesEntry?.bids}
          />
        )}

        <Flex
          direction={{ base: 'column', md: 'column-reverse' }}
          grow={1}
          justify="end"
        >
          <Flex
            justifyContent="space-between"
            {...variantStyles.stats[variant]}
          >
            <Box>
              <Text variant="bold">{formatCurrency(displayPrice.amount)}</Text>
              <Text
                variant="subdued"
                fontSize={{ base: fontSizes.supplementary, md: fontSizes.body }}
              >
                <BidCount entry={entry} />
              </Text>
            </Box>
            <Box>
              <Text
                variant="bold"
                textAlign="right"
              >
                {!entry.ended && (
                  <TimeLeft
                    endsAt={new Date(entry.auctionEnd)}
                    id={entry.id}
                  />
                )}
              </Text>
              <Text
                variant="subdued"
                textAlign="right"
                fontSize={{ base: fontSizes.supplementary, md: fontSizes.body }}
              >
                <TimeLeftDescription
                  entryId={entry.id}
                  isEnded={entry.ended}
                  isCancelled={entry.cancelled}
                  auctionEnd={new Date(entry.auctionEnd)}
                  isContinuousAuction={entry.continuousAuction}
                  bidCount={entry.bidCount}
                />
              </Text>
            </Box>
          </Flex>
          {currentUser.data?.isAuthenticated && (
            <ListSmsNotificationToggle
              entryId={entry.id}
              vatPerc={entry.vatPerc}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        backgroundColor="lineBorder"
        position="relative"
        overflow="hidden"
        {...variantStyles.imageContainer[variant]}
      >
        <Box
          position="relative"
          flexGrow={1}
          {...variantStyles.imageItem[variant]}
        >
          <HuutokaupatImage
            src={entry.mainPhoto}
            alt=""
            fill
            style={{ objectFit: 'cover' }}
            sizes={`(max-width: ${breakpoints.md}) 180px, (max-width: ${breakpoints.xl}) 380px, 400px`}
          />
          {entry.isFastBidPeriodEntry && (
            <Flex
              maxWidth={{ base: 75, md: 120 }}
              position="absolute"
              bottom={0}
              left={0}
              zIndex={5}
            >
              <Image
                src={HuutoHulinatIcon} // eslint-disable-line @typescript-eslint/no-unsafe-assignment
                alt="Huutohulinat-kohde"
              />
            </Flex>
          )}
        </Box>
        <ToggleFavoriteButton
          position="absolute"
          zIndex="docked"
          entryId={entry.id}
          top={0}
          margin={2}
          size="sm"
          ecommerceItem={listEntryToEcommerceItem(entry, categories, analytics.index)}
        />
        {badges
          .filter((item) => item.enabled)
          .slice(0, 1)
          .map((badge) => (
            <Box
              key={badge.key}
              backgroundColor={badge.background}
              fontSize={fontSizes.supplementary}
              textAlign="center"
              lineHeight="1.3em"
              zIndex={10}
              right={0}
              {...variantStyles.highestBidderText[variant]}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                minHeight={{ base: '24px', sm: 'auto' }}
                gap={1}
              >
                {badge.label}
              </Flex>
            </Box>
          ))}
      </Flex>
    </EntryListItem>
  );
}

export default EntryCard;
